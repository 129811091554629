

.serve-section {
  padding: var(--spacing-80);
  background-color: var(--background-white);
}

.serve-container {
  max-width: 1500px;
  margin: 0 auto;
}

.serve-header {
  text-align: center;
  margin-bottom: var(--spacing-64);
}

.serve-title {
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  text-transform: uppercase;
  color: var(--primary-text);
}

.serve-description {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  text-align: center;
  color: var(--primary-text);
  max-width: 704px;
  margin: var(--spacing-12) auto;
}

.main-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-32);
  margin-bottom: var(--spacing-64);
}

.main-card {
  background: var(--background-white);
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.card-image {
  width: 100%;
  height: 300px;
  overflow: hidden;
}

.contractor-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-title {
  margin: var(--spacing-24) var(--spacing-24) var(--spacing-12);
  font-weight: 700;
  font-size: 20px;
  line-height: 1.4;
  color: var(--primary-text);
}

.card-description {
  margin: 0 var(--spacing-24) var(--spacing-24);
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: var(--primary-text);
}

.service-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-32);
}

.service-card {
  padding: var(--spacing-32) var(--spacing-32) var(--spacing-12);
  background: var(--background-white);
  border-radius: 16px;
  border: 1px solid var(--border-color);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.service-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.service-icon {
  width: 48px;
  height: 48px;
  background: #e6edff;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--spacing-24);
}

.icon {
  width: 24px;
  height: 24px;
  color: var(--primary-color);
}

.service-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.4;
  color: var(--primary-text);
  margin: var(--spacing-8) auto;
}

.service-description {
  margin: var(--spacing-8) auto;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: var(--primary-text);
}

@media (max-width: 1024px) {
  .service-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .serve-section {
    padding: 60px var(--spacing-24);
  }

  .serve-header {
    text-align: left;
  }

  .serve-title {
    font-size: 40px;
    text-align: left;
  }

  .serve-description {
    font-size: var(--font-md);
    text-align: left;
    margin-left: 0;
    margin-right: 0;
  }

  .main-cards {
    grid-template-columns: 1fr;
  }

  .service-cards {
    grid-template-columns: 1fr;
  }

  .card-title,
  .card-description,
  .service-title,
  .service-description {
    text-align: left;
  }
}

@media (max-width: 480px) {
  .serve-section {
    padding: 48px var(--spacing-16);
  }

  .serve-title {
    font-size: 32px;
  }

  .card-image {
    height: 200px;
  }
}