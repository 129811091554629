.coming-section {
  padding: 80px var(--spacing-80) 40px;
  background-color: var(--background-light);
}

.coming-container {
  max-width: 1500px;
  margin: 0 auto;
}

.coming-title {
  font-weight: 600;
  font-size: 32px;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
  color: var(--primary-text);
  margin-bottom: var(--spacing-40);
}

.feature-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-32);
}

.cs-feature-card {
  background: var(--background-white);
  border-radius: 16px;
  padding: var(--spacing-24) 0px;
  border: 1px solid var(--border-color);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.cs-feature-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.feature-image-container {
  margin-bottom: var(--spacing-24);
  border-radius: 8px;
  overflow: hidden;
}

.feature-image {
  width: 100%;
  height: auto;
  display: block;
}

.feature-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 1.3;
  color: var(--primary-text);
  margin-bottom: var(--spacing-12);
}

.cs-feature-card-container {
  padding: 0px var(--spacing-24);
}

.feature-description {
  font-weight: 400;
  font-size: var(--font-md);
  line-height: 1.5;
  color: var(--secondary-text);
  max-width: 576px;
}

@media (max-width: 1024px) {
  .coming-section {
    padding: 70px var(--spacing-20) 40px;
  }

  .coming-title {
    font-size: 28px;
  }

  .feature-title {
    font-size: 28px;
  }
}

@media (max-width: 768px) {
  .coming-section {
    padding: 60px var(--spacing-16) 40px;
  }

  .coming-title {
    font-size: 24px;
    margin-bottom: var(--spacing-32);
  }

  .feature-grid {
    grid-template-columns: 1fr;
    gap: var(--spacing-24);
  }

  .feature-title {
    font-size: 24px;
  }
}

@media (max-width: 480px) {
  .coming-section {
    padding: 48px var(--spacing-12) 32px;
  }

  .coming-title {
    font-size: 22px;
  }

  .feature-title {
    font-size: 20px;
  }

  .cs-feature-card {
    padding: var(--spacing-16) 0px;
  }

  .cs-feature-card-container {
    padding: 0px var(--spacing-16);
  }
}
