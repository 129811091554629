.nav-container {
  width: 100%;
  height: 70px;
  background: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);
  position: sticky;
  top: 0px;
  z-index: 1000;
}
.item-label {
  cursor: pointer;
  user-select: none;
}
.nav-content {
  max-width: 1500px;
  height: 100%;
  margin: 0 auto;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-logo {
  width: 100px;
  height: 30px;
  margin-top: 6px;
  z-index: 1001;
  outline: none;
}
.nav-logo:focus-visible {
  outline: none;
}

.nav-items-container {
  display: flex;
  align-items: center;
  gap: 32px;
}

.nav-items {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 32px;
}

.nav-items {
  position: relative;
}

.nav-item-content {
  display: flex;
  align-items: center;
  gap: 4px;
}

.nav-item:hover,
.nav-item-content a:hover {
  color: var(--primary-hover);
}
.nav-item:hover .dropdown-arrow {
  stroke: var(--primary-hover);
}

.nav-subItem {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}
.nav-item a {
  color: #1a1a1a;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.2s ease;
}

/* .nav-item:hover a {
  color: var(--primary-color);
} */

.dropdown-toggle {
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdown-arrow {
  transition: transform 0.2s ease;
}

.dropdown-arrow.active {
  transform: rotate(180deg);
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  min-width: 200px;
  padding: 8px 0;
  margin-top: 8px;
}

.dropdown-item {
  display: block;
  padding: 8px 16px;
  cursor: pointer;
  transition: background 0.2s ease;
  text-decoration: none;
  color: #1a1a1a;
}

.dropdown-item:hover {
  background: #f5f5f5;
}

.nav-buttons {
  display: flex;
  gap: 12px;
}

.btn {
  padding: 10px 12px;
  border-radius: 30px;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
  white-space: nowrap;
  text-decoration: none;
}

.btn-primary {
  background: var(--primary-color);
  color: white;
}

.btn-primary:hover {
  background: var(--primary-color);
}

.btn-secondary {
  background: #1a1a1a;
  color: white;
}

.btn-secondary:hover {
  background: #333;
}

.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 24px;
  height: 20px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1001;
}

.hamburger-line {
  width: 100%;
  height: 2px;
  background-color: #1a1a1a;
  transition: all 0.3s ease;
}

.hamburger.active .hamburger-line:nth-child(1) {
  transform: translateY(9px) rotate(45deg);
}

.hamburger.active .hamburger-line:nth-child(2) {
  opacity: 0;
}

.hamburger.active .hamburger-line:nth-child(3) {
  transform: translateY(-9px) rotate(-45deg);
}

.mobile-buttons {
  display: none;
}

.mobile-overlay {
  display: none;
}
.menu-link {
  display: flex;
  height: 100%; /* Makes it take the full height of the parent */
  text-decoration: none; /* Removes default link styling */
}
.menu-section:hover {
  border: 1px solid rgba(23, 130, 224, 0.15);
  background-color: rgba(23, 130, 224, 0.03);
}

@media (max-width: 768px) {
  .hamburger {
    display: flex;
  }

  .nav-items-container {
    position: fixed;
    top: 0;
    right: -300px;
    width: 300px;
    height: 100vh;
    background: white;
    flex-direction: column;
    padding: 80px 24px 24px;
    transition: right 0.3s ease;
    box-shadow: -2px 0 8px rgba(0, 0, 0, 0.1);
  }

  .nav-items-container.open {
    right: 0;
    z-index: 1000;
  }

  .nav-items {
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  .nav-item {
    width: 100%;
  }

  .nav-item-content {
    justify-content: space-between;
  }

  .dropdown-menu {
    position: static;
    box-shadow: none;
    margin-top: 8px;
    margin-left: 16px;
  }

  .desktop-buttons {
    display: none;
  }

  .mobile-buttons {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
    margin-top: 24px;
  }

  .mobile-overlay {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
}

.nav-menu {
  position: absolute;
  top: 135%;
  left: -60%;
  right: 0;
  background: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 8px 32px 24px;
  z-index: 1000;
  width: fit-content;
}

.nav-menu-container {
  max-width: 1500px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1fr;
  gap: 0px;
}

/* Platform Preview Section */
.platform-section {
  padding-right: 48px;
  width: fit-content;
  min-width: 408px;
}

.platform-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  color: #000000;
}

.platform-description {
  font-weight: 400;
  font-size: 14px;
  text-align: justify;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.6);
}

.preview-card {
  background: #f8f9fa;
  border-radius: 12px;
  padding: 16px;
}

.preview-header {
  margin-bottom: 16px;
}

.preview-header h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px;
}

.preview-subtitle {
  font-size: 12px;
  color: #666;
}

.preview-stats {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.stat-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.stat-header {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #333;
}

.stat-icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

.stat-icon.project {
  background: #ffe5e5;
}

.stat-values {
  display: flex;
  gap: 24px;
}

.stat-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.stat-number {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

.stat-label {
  font-size: 12px;
  color: #666;
}

/* Menu Sections */
.menu-section {
  padding: 24px;
  min-width: 300px;
  display: flex;
  align-items: flex-start;
  margin: 12px;
  border-radius: 16px;
}
.menu-section img {
  max-width: 30px;
  margin-right: 4px;
}

.menu-title {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 12px;
  color: #000;
  margin-top: 0px;
}
.menu-title a:hover {
  color: var(--primary-color);
}
.menu-icon {
  font-size: 20px;
}

.menu-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.menu-list li {
  text-decoration: none;
  transition: color 0.2s ease;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
}

.menu-list li a:hover {
  color: var(--primary-color);
}

.menu-item-disabled {
  color: #999;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.coming-soon {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.4);
}

@media (max-width: 1024px) {
  .nav-menu-container {
    grid-template-columns: 1fr;
    gap: 32px;
  }

  .platform-section {
    padding-right: 0;
    border-right: none;
    border-bottom: 1px solid #eee;
    padding-bottom: 32px;
  }
}
@media (max-width: 786px) {
  .nav-subItem {
    width: 100%;
    align-items: center;
  }
}

.custom-btn {
  max-height: 35px;
  padding-top: 8px;
}
