.insights-section {
  width: 100%;
  padding: 80px 0px;
  background-color: #ffffff;
  border-top: 1px solid var(--border-color);
}

.insights-container {
  max-width: 1500px;
  margin: 0 auto;
}

.insights-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 64px;
  align-items: center;
  padding: 0px 80px;
}

.insights-text {
  max-width: 700px;
}

.ai-label {
  display: inline-block;
  padding: 4px 12px;
  background-color: transparent;
  color: var(--primary-color);
  border-radius: 16px;
  font-size: var(--font-sm);
  margin-bottom: 0px;
}

.insights-title {
  font-weight: 600;
  font-size: 32px;
  line-height: 1.2;
  text-transform: uppercase;
  color: var(--primary-text);
  margin-top: var(--spacing-16);
  margin-bottom: var(--spacing-16);
}

.insights-description {
  font-weight: 400;
  font-size: var(--font-md);
  line-height: 1.5;
  color: var(--secondary-text);
}

.bt {
  border-top: 1px solid var(--border-color);
}

.insights-visual {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.insights-image {
  width: 100%;
  max-width: 700px;
  height: auto;
}

@media (max-width: 1024px) {
  .insights-section {
    padding: 60px 0px;
  }

  .insights-content {
    grid-template-columns: 1fr;
    gap: var(--spacing-32);
    padding: 0px var(--spacing-20);
  }

  .insights-text {
    max-width: 100%;
  }

  .insights-visual {
    justify-content: center;
    order: -1;
  }

  .insights-title {
    font-size: 28px;
  }
}

@media (max-width: 768px) {
  .insights-section {
    padding: 48px 0px;
  }

  .insights-content {
    padding: 0px var(--spacing-16);
  }

  .insights-title {
    font-size: 24px;
  }

  .ai-label {
    font-size: var(--font-md);
    margin-bottom: var(--spacing-12);
    padding: 0px;
  }
}

@media (max-width: 480px) {
  .insights-section {
    padding: 40px 0px;
  }

  .insights-content {
    padding: 0px var(--spacing-12);
    gap: var(--spacing-24);
  }

  .insights-title {
    font-size: 22px;
  }
}
