.reports-section {
  background-color: #fff;
  width: 100%;
  padding: 80px 80px;
  border-top: 1px solid var(--border-color);
}

.reports-container {
  max-width: 1500px;
  margin: 0 auto;
}

.reports-header {
  margin-bottom: 64px;
}

.reports-label {
  display: inline-block;
  padding: 4px 12px;
  background-color: #e6edff;
  color: var(--primary-color);
  border-radius: 16px;
  font-size: var(--font-sm);
  margin-bottom: 0px;
}

.reports-title {
  font-size: 48px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 24px;
  color: var(--primary-text);
  margin-top: 16px;
  max-width: 800px;
}

.reports-description {
  font-size: var(--font-lg);
  line-height: 1.6;
  color: var(--secondary-text);
  max-width: 600px;
}

.reports-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 48px;
  align-items: stretch;
}

.reports-interface {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #f7f7f9;
  border-radius: 12px;
  padding-left: 15%;
  height: 100%;
}

.interface-image {
  width: 100%;
  height: auto;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.report-types {
  display: flex;
  flex-direction: column;
  gap: 63px;
}

.report-card {
  border-radius: 12px;
  padding: 20px 24px 0px;
  border: 1px solid transparent;
  background-color: transparent;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
}

.report-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.report-card.active {
  background-color: #f7f7f9;
  border: 1px solid var(--border-color);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.report-card-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0px;
  color: var(--primary-text);
  margin-top: 8px;
}

.report-card-description {
  font-size: var(--font-md);
  line-height: 1.6;
  color: var(--secondary-text);
  margin-top: 8px;
}

@media (max-width: 1024px) {
  .reports-section {
    padding: 60px var(--spacing-20);
  }

  .reports-title {
    font-size: 42px;
  }

  .reports-description {
    font-size: var(--font-md);
  }

  .reports-content {
    grid-template-columns: 1fr;
    gap: var(--spacing-32);
  }

  .reports-interface {
    order: 2;
  }

  .report-types {
    order: 1;
    gap: var(--spacing-24);
  }

  .report-card-title {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .reports-section {
    padding: 48px var(--spacing-16);
  }

  .reports-header {
    text-align: left;
    margin-bottom: var(--spacing-32);
  }

  .reports-title {
    font-size: 36px;
  }

  .reports-description {
    max-width: 100%;
  }

  .report-types {
    gap: var(--spacing-20);
  }
}

@media (max-width: 480px) {
  .reports-section {
    padding: 40px var(--spacing-12);
  }

  .reports-title {
    font-size: 32px;
  }

  .reports-label {
    font-size: var(--font-md);
  }

  .report-card {
    padding: var(--spacing-16) var(--spacing-16) 0;
  }
}