.cta-section {
  width: 100%;
  min-height: 400px;
  padding: 80px 20px;
  background-color: #000000;
  background-image: url("../../assets/ctaBg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cta-wrapper {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  z-index: 1;
  background-color: transparent;
}

.cta-subtitle {
  display: inline-block;
  font-size: var(--font-md);
  color: var(--primary-color);
  margin-bottom: 0px;
  padding-top: 5%;
  background-color: transparent;
}

.cta-heading {
  font-size: 48px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 0px;
  margin-top: 12px;
  line-height: 1.2;
  background-color: transparent;
}

.cta-description {
  font-size: var(--font-lg);
  line-height: 1.6;
  color: #e5e7eb;
  margin-top: 8px;
  margin-bottom: 28px;
  background-color: transparent;
}

.demo-button {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  background-color: var(--primary-color);
  color: white;
  padding: 12px 24px;
  border-radius: 30px;
  text-decoration: none;
  transition: all 0.2s ease;
}

.demo-button:hover {
  background-color: var(--active-text);
  transform: translateY(-2px);
}

.arrow {
  background-color: transparent;
  font-size: 20px;
  color: #fff;
  transition: transform 0.2s ease;
}

.demo-button:hover .arrow {
  transform: translateX(4px);
}

/* Responsive Design */
@media (max-width: 1024px) {
  .cta-section {
    padding: 70px var(--spacing-20);
  }

  .cta-heading {
    font-size: 42px;
  }

  .cta-description {
    font-size: var(--font-md);
  }
}

@media (max-width: 768px) {
  .cta-section {
    padding: 60px var(--spacing-16);
    min-height: 350px;
  }



  .cta-heading {
    font-size: 36px;
  }

  .cta-description {
    font-size: var(--font-sm);
  }
}

@media (max-width: 480px) {
  .cta-section {
    padding: 40px var(--spacing-12);
    min-height: 300px;
  }

  .cta-heading {
    font-size: 28px;
  }

  .cta-subtitle {
    font-size: var(--font-sm);
  }

  .cta-description {
    font-size: var(--font-xs);
  }

  .demo-button {
    width: 100%;
    justify-content: center;
  }
}