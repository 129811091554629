.ai-section {
  padding: 80px 0px 40px;
  background-color: var(--background-white);
}

.ai-container {
  max-width: 1500px;
  margin: 0 auto;
}

.ai-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-64);
  align-items: center;
  padding: 0px 80px;
}

.ai-visual {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.dai-interface-image {
  width: 100%;
  max-width: 700px;
  height: auto;
}

.ai-text {
  max-width: 520px;
}

.ai-label {
  display: inline-block;
  padding: 4px 12px;
  background-color: #e6edff;
  color: var(--primary-color);
  border-radius: 16px;
  font-size: var(--font-sm);
  margin-bottom: var(--spacing-24);
}

.ai-title {
  font-weight: 600;
  font-size: 32px;
  line-height: 1.2;
  text-transform: uppercase;
  color: var(--primary-text);
  margin: var(--spacing-12) 0px;
}

.mt-24 {
  margin-top: var(--spacing-40);
}

.ai-description {
  font-weight: 400;
  font-size: var(--font-md);
  line-height: 1.5;
  color: var(--primary-text);
}

.ul {
  margin: 0px;
  margin-left: -12px;
  padding-left: var(--spacing-24);
}

.bt {
  border-top: 1px solid var(--border-color);
  margin-top: var(--spacing-40);
  padding-top: var(--spacing-40);
}

@media (max-width: 1024px) {
  .ai-section {
    padding: 70px var(--spacing-20);
  }

  .ai-content {
    grid-template-columns: 1fr;
    gap: var(--spacing-48);
    padding: 0;
  }

  .ai-text {
    max-width: 100%;
  }

  .ai-visual {
    justify-content: center;
  }

  .dai-interface-image {
    max-width: 100%;
  }

  .ai-title {
    font-size: 28px;
  }
}

@media (max-width: 768px) {
  .ai-section {
    padding: 60px var(--spacing-16);
  }

  .ai-title {
    font-size: 24px;
  }

  .ai-description {
    font-size: var(--font-md);
  }

  .ai-label {
    font-size: var(--font-md);
    margin-bottom: var(--spacing-12);
    padding: 0px;
  }

  .mt-24 {
    margin-top: var(--spacing-24);
  }

  .bt {
    margin-top: var(--spacing-32);
    padding-top: var(--spacing-32);
  }
}

@media (max-width: 480px) {
  .ai-section {
    padding: 48px var(--spacing-12);
  }

  .ai-title {
    font-size: 22px;
  }
}
