.solutions-section {
  padding: var(--spacing-80) var(--spacing-24);
  background-color: var(--background-white);
  text-align: center;
}

.solutions-container {
  max-width: 1500px;
  margin: 0 auto;
}

.solutions-label {
  display: inline-block;
  padding: 4px var(--spacing-12);
  background-color: transparent;
  color: var(--primary-color);
  border-radius: 16px;
  font-size: var(--font-sm);
  margin-bottom: 0;
}

.solutions-title {
  font-weight: 900;
  font-size: 74px;
  line-height: 1.08;
  text-align: center;
  color: var(--primary-text);
  margin-bottom: 0;
  margin-top: var(--spacing-12);
}

.solutions-description {
  font-weight: 400;
  font-size: var(--font-md);
  line-height: 1.5;
  text-align: center;
  color: var(--secondary-text);
  max-width: 976px;
  margin: var(--spacing-12) auto var(--spacing-16);
}

.demo-button {
  display: inline-flex;
  align-items: center;
  gap: var(--spacing-8);
  padding: var(--spacing-12) var(--spacing-24);
  background-color: var(--primary-color);
  color: var(--background-white);
  border: none;
  border-radius: 30px;
  font-size: var(--font-md);
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
  margin-bottom: var(--spacing-48);
  text-decoration: none !important;
}

.demo-button:hover {
  background-color: var(--primary-hover);
}

.arrow {
  font-size: 20px;
  transition: transform 0.2s ease;
}

.demo-button:hover .arrow {
  transform: translateX(4px);
}

.video-container {
  position: relative;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.demo-video {
  width: 100%;
  aspect-ratio: 16 / 9;
  border: none;
}

@media (max-width: 1024px) {
  .solutions-section {
    padding: 70px var(--spacing-24);
  }

  .solutions-title {
    font-size: 60px;
  }
}

@media (max-width: 768px) {
  .solutions-section {
    padding: 60px var(--spacing-16);
    text-align: left;
  }

  .solutions-container {
    max-width: 100%;
  }

  .solutions-title {
    font-size: 48px;
    text-align: left;
  }

  .solutions-description {
    font-size: var(--font-md);
    text-align: left;
    margin-left: 0;
    margin-right: 0;
  }

  .solutions-label {
    padding: 0;
    margin-bottom: var(--spacing-12);
  }

  .demo-button {
    margin-bottom: var(--spacing-24);
  }
}

@media (max-width: 480px) {
  .solutions-section {
    padding: 48px var(--spacing-12);
  }

  .solutions-title {
    font-size: 36px;
  }

  .solutions-label {
    font-size: var(--font-md);
  }

  .demo-button {
    padding: var(--spacing-8) var(--spacing-16);
    font-size: var(--font-sm);
  }
}
