.project-schedule-section {
  padding: 80px 24px;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #f5f6f8;
}

.w-full {
  max-width: 100%;
}

.m-8-ve {
  margin-bottom: -8px;
}

.project-schedule-container {
  max-width: 900px;
  margin: 0 auto;
}

.project-platform-label {
  display: inline-block;
  padding: 4px 12px;
  background-color: transparent;
  color: var(--primary-color);
  border-radius: 16px;
  font-size: var(--font-sm);
  margin-bottom: 24px;
}

.project-schedule-title {
  font-size: 64px;
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 24px;
  color: var(--primary-text);
  margin-top: 0px;
}

.project-schedule-description {
  font-size: var(--font-lg);
  line-height: 1.6;
  color: var(--secondary-text);
  max-width: 800px;
  margin: 0 auto;
}

@media (max-width: 1024px) {
  .project-schedule-section {
    padding: 70px var(--spacing-20);
  }

  .project-schedule-title {
    font-size: 56px;
  }

  .project-schedule-description {
    font-size: var(--font-md);
  }
}

@media (max-width: 768px) {
  .project-schedule-section {
    padding: 60px var(--spacing-16);
    text-align: left;
  }

  .project-schedule-container {
    max-width: 100%;
  }

  .project-schedule-title {
    font-size: 48px;
  }

  .project-schedule-description {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 480px) {
  .project-schedule-section {
    padding: 48px var(--spacing-12);
  }

  .project-schedule-title {
    font-size: 40px;
    margin: 8px 0px;
  }

  .project-platform-label {
    font-size: var(--font-sm);
    margin-bottom: 12px;
    padding: 0px;
  }
}
