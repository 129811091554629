.dashboard {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 48px;
  min-height: 100vh;
  background-color: #f5f5f5;
}

.dashboard-left {
  background-image: url("../../assets/p2.png");
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
  margin-top: 14px;
  background-color: transparent;
}

.dashboard-left-content {
  margin-top: 100px;
  padding: 80px;
  background-color: transparent;
}

.platform-label {
  display: inline-block;
  color: var(--primary-color);
  font-size: var(--font-sm);
  margin-bottom: 0px;
  margin-top: 20px;
}

.dashboard-title {
  font-size: 64px;
  font-weight: 700;
  background-color: transparent;
  line-height: 1.1;
  margin-bottom: 0px;
  margin-top: 20px;
  color: var(--primary-text);
}

.dashboard-description {
  font-size: var(--font-lg);
  line-height: 1.6;
  color: var(--secondary-text);
  background-color: transparent;
  max-width: 600px;
}

.dashboard-right {
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 0px;
  overflow-x: hidden;
  background-color: transparent;
}

.dashboard-interface {
  transform: translateX(14px);
  width: 100%;
  height: auto;
  max-width: 800px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

@media (max-width: 1024px) {
  .dashboard {
    grid-template-columns: 1fr;
    gap: var(--spacing-24);
  }

  .dashboard-left-content {
    margin-top: 60px;
    padding: var(--spacing-20);
  }

  .dashboard-title {
    font-size: 48px;
  }

  .dashboard-description {
    font-size: var(--font-md);
  }

  .dashboard-right {
    padding: 0 var(--spacing-20) var(--spacing-12) 0;
  }
}

@media (max-width: 768px) {
  .dashboard {
    padding: var(--spacing-16);
  }

  .dashboard-left-content {
    margin-top: 40px;
    padding: var(--spacing-16);
  }

  .dashboard-title {
    font-size: 40px;
  }

  .dashboard-description {
    font-size: var(--font-md);
  }

  .platform-label {
    font-size: var(--font-xs);
  }
}

@media (max-width: 480px) {
  .dashboard {
    padding: var(--spacing-12);
  }

  .dashboard-left-content {
    margin-top: 20px;
    padding: var(--spacing-12);
  }

  .dashboard-title {
    font-size: 32px;
  }

  .dashboard-description {
    font-size: var(--font-md);
  }

  .dashboard-right {
    padding: 0 var(--spacing-12) var(--spacing-12) 0;
  }
}
