@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
/* :root {
  --primary-text: #1f1f1f;
  --secondary-text: #555555;
  --active-text: #1e7eb2;
  --background-white: #fff;
  --primary-hover: #0052cc;

  --primary-color: #1782e0;

  --border-color: #edeef1;

  --spacing-4: 4px;
  --spacing-8: 8px;
  --spacing-12: 12px;
  --spacing-16: 16px;
  --spacing-20: 20px;
  --spacing-24: 24px;
  --spacing-32: 2rem;
  --spacing-40: 2.5rem;
  --spacing-80: 5rem;

  --font-xs: 12px;
  --font-sm: 14px;
  --font-md: 16px;
  --font-lg: 18px;
  --font-xl: 20px;
} */
:root {
  /* Text Colors */
  --primary-text: #1f1f1f;
  --secondary-text: #555555;
  --active-text: #1e7eb2;
  --background-white: #fff;

  /* Brand Colors */
  --primary-color: #1782e0;
  --primary-hover: #0052cc;

  /* Border Colors */
  --border-color: #edeef1;

  /* Spacing */
  --spacing-4: 4px;
  --spacing-8: 8px;
  --spacing-12: 12px;
  --spacing-16: 16px;
  --spacing-20: 20px;
  --spacing-24: 24px;
  --spacing-32: 32px;
  --spacing-40: 40px;
  --spacing-48: 48px;
  --spacing-64: 64px;
  --spacing-80: 80px;

  /* Font Sizes */
  --font-xs: 12px;
  --font-sm: 14px;
  --font-md: 16px;
  --font-lg: 18px;
  --font-xl: 20px;
}
.big-blue-bullet {
  font-size: 32px; /* Adjust size */
  color: var(--primary-hover); /* Set color */
}
.flex-center {
  display: flex;
  align-items: center;
}
.text-justify{
  text-align: justify;
}
.ty-10{
  transform: translateY(-10px);
}
@keyframes pulse {
  0% {
    opacity: 0.6;
    transform: scale(1);
  }
  50% {
    opacity: 0.1;
    transform: scale(1.05);
  }
  100% {
    opacity: 0.6;
    transform: scale(1);
  }
}

.primary-border {
  border: 1px solid var(--border-color);
}

.boder-top {
  border-top: 1px solid #eeeeee;
}
.text-center {
  text-align: center;
}

body {
  font-family: "Roboto", sans-serif !important;
}
.mb-0 {
  margin-bottom: 0px;
}
.mb-4 {
  margin-bottom: 4px;
}
.mt-0 {
  margin-top: 0px;
}
.my-0 {
  margin-bottom: 0px;
  margin-top: 0px;
}
.lh-0 {
  line-height: 0px;
}
.bg-transparent {
  background-color: transparent !important;
}
.demo-button {
  max-width: 230px;
}
.demo-button:hover,
.custom-btn:hover {
  color: #fff !important;
  text-decoration: none !important;
}
.loader-overlay {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  overflow: hidden;
}

.loader-container {
  /* background-color: white; */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  border-radius: 4px;
  padding: 16px;
  animation: blink 1.5s ease-in-out infinite;
}

.loader-image {
  display: block;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

@keyframes blink {
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}
.input-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 250px; /* Adjust width as needed */
}

.custom-input {
  width: 100%;
  padding: 8px 12px;
  padding-right: 40px; /* Space for button */
  border: 1px solid var(--border-color);
  border-radius: 6px;
  outline: none;
  transition: border-color 0.2s ease-in-out;
}

.custom-input::-webkit-outer-spin-button,
.custom-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-button {
  position: absolute;
  right: 1px;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--primary-color);
  border: none;
  padding: 4px;
  border-radius: 0px 4px 4px 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.2s ease-in-out;
  width: 32px;
  height: 32px;
  font-size: 18px;
  color: #fff;
}

.custom-button:hover {
  background-color: var(--primary-hover);
}

.icon {
  width: 16px;
  height: 16px;
  stroke: white;
}

.nav-container {
  width: 100%;
  height: 70px;
  background: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);
  position: sticky;
  top: 0px;
  z-index: 1000;
}
.item-label {
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}
.nav-content {
  max-width: 1500px;
  height: 100%;
  margin: 0 auto;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-logo {
  width: 100px;
  height: 30px;
  margin-top: 6px;
  z-index: 1001;
  outline: none;
}
.nav-logo:focus-visible {
  outline: none;
}

.nav-items-container {
  display: flex;
  align-items: center;
  grid-gap: 32px;
  gap: 32px;
}

.nav-items {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  grid-gap: 32px;
  gap: 32px;
}

.nav-items {
  position: relative;
}

.nav-item-content {
  display: flex;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
}

.nav-item:hover,
.nav-item-content a:hover {
  color: var(--primary-hover);
}
.nav-item:hover .dropdown-arrow {
  stroke: var(--primary-hover);
}

.nav-subItem {
  display: flex;
  justify-content: space-between;
  grid-gap: 40px;
  gap: 40px;
}
.nav-item a {
  color: #1a1a1a;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.2s ease;
}

/* .nav-item:hover a {
  color: var(--primary-color);
} */

.dropdown-toggle {
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdown-arrow {
  transition: transform 0.2s ease;
}

.dropdown-arrow.active {
  transform: rotate(180deg);
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  min-width: 200px;
  padding: 8px 0;
  margin-top: 8px;
}

.dropdown-item {
  display: block;
  padding: 8px 16px;
  cursor: pointer;
  transition: background 0.2s ease;
  text-decoration: none;
  color: #1a1a1a;
}

.dropdown-item:hover {
  background: #f5f5f5;
}

.nav-buttons {
  display: flex;
  grid-gap: 12px;
  gap: 12px;
}

.btn {
  padding: 10px 12px;
  border-radius: 30px;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
  white-space: nowrap;
  text-decoration: none;
}

.btn-primary {
  background: var(--primary-color);
  color: white;
}

.btn-primary:hover {
  background: var(--primary-color);
}

.btn-secondary {
  background: #1a1a1a;
  color: white;
}

.btn-secondary:hover {
  background: #333;
}

.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 24px;
  height: 20px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1001;
}

.hamburger-line {
  width: 100%;
  height: 2px;
  background-color: #1a1a1a;
  transition: all 0.3s ease;
}

.hamburger.active .hamburger-line:nth-child(1) {
  transform: translateY(9px) rotate(45deg);
}

.hamburger.active .hamburger-line:nth-child(2) {
  opacity: 0;
}

.hamburger.active .hamburger-line:nth-child(3) {
  transform: translateY(-9px) rotate(-45deg);
}

.mobile-buttons {
  display: none;
}

.mobile-overlay {
  display: none;
}
.menu-link {
  display: flex;
  height: 100%; /* Makes it take the full height of the parent */
  text-decoration: none; /* Removes default link styling */
}
.menu-section:hover {
  border: 1px solid rgba(23, 130, 224, 0.15);
  background-color: rgba(23, 130, 224, 0.03);
}

@media (max-width: 768px) {
  .hamburger {
    display: flex;
  }

  .nav-items-container {
    position: fixed;
    top: 0;
    right: -300px;
    width: 300px;
    height: 100vh;
    background: white;
    flex-direction: column;
    padding: 80px 24px 24px;
    transition: right 0.3s ease;
    box-shadow: -2px 0 8px rgba(0, 0, 0, 0.1);
  }

  .nav-items-container.open {
    right: 0;
    z-index: 1000;
  }

  .nav-items {
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
    width: 100%;
  }

  .nav-item {
    width: 100%;
  }

  .nav-item-content {
    justify-content: space-between;
  }

  .dropdown-menu {
    position: static;
    box-shadow: none;
    margin-top: 8px;
    margin-left: 16px;
  }

  .desktop-buttons {
    display: none;
  }

  .mobile-buttons {
    display: flex;
    flex-direction: column;
    width: 100%;
    grid-gap: 8px;
    gap: 8px;
    margin-top: 24px;
  }

  .mobile-overlay {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
}

.nav-menu {
  position: absolute;
  top: 135%;
  left: -60%;
  right: 0;
  background: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 8px 32px 24px;
  z-index: 1000;
  width: -moz-fit-content;
  width: fit-content;
}

.nav-menu-container {
  max-width: 1500px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1fr;
  grid-gap: 0px;
  gap: 0px;
}

/* Platform Preview Section */
.platform-section {
  padding-right: 48px;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 408px;
}

.platform-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  color: #000000;
}

.platform-description {
  font-weight: 400;
  font-size: 14px;
  text-align: justify;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.6);
}

.preview-card {
  background: #f8f9fa;
  border-radius: 12px;
  padding: 16px;
}

.preview-header {
  margin-bottom: 16px;
}

.preview-header h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px;
}

.preview-subtitle {
  font-size: 12px;
  color: #666;
}

.preview-stats {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;
}

.stat-group {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
}

.stat-header {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  font-size: 14px;
  color: #333;
}

.stat-icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

.stat-icon.project {
  background: #ffe5e5;
}

.stat-values {
  display: flex;
  grid-gap: 24px;
  gap: 24px;
}

.stat-item {
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
}

.stat-number {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

.stat-label {
  font-size: 12px;
  color: #666;
}

/* Menu Sections */
.menu-section {
  padding: 24px;
  min-width: 300px;
  display: flex;
  align-items: flex-start;
  margin: 12px;
  border-radius: 16px;
}
.menu-section img {
  max-width: 30px;
  margin-right: 4px;
}

.menu-title {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 12px;
  color: #000;
  margin-top: 0px;
}
.menu-title a:hover {
  color: var(--primary-color);
}
.menu-icon {
  font-size: 20px;
}

.menu-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  gap: 12px;
}

.menu-list li {
  text-decoration: none;
  transition: color 0.2s ease;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
}

.menu-list li a:hover {
  color: var(--primary-color);
}

.menu-item-disabled {
  color: #999;
  font-size: 14px;
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.coming-soon {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.4);
}

@media (max-width: 1024px) {
  .nav-menu-container {
    grid-template-columns: 1fr;
    grid-gap: 32px;
    gap: 32px;
  }

  .platform-section {
    padding-right: 0;
    border-right: none;
    border-bottom: 1px solid #eee;
    padding-bottom: 32px;
  }
}
@media (max-width: 786px) {
  .nav-subItem {
    width: 100%;
    align-items: center;
  }
}

.custom-btn {
  max-height: 35px;
  padding-top: 8px;
}

.cta-section {
  width: 100%;
  min-height: 400px;
  padding: 80px 20px;
  background-color: #000000;
  background-image: url(/static/media/ctaBg.9ea8b10e.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cta-wrapper {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  z-index: 1;
  background-color: transparent;
}

.cta-subtitle {
  display: inline-block;
  font-size: var(--font-md);
  color: var(--primary-color);
  margin-bottom: 0px;
  padding-top: 5%;
  background-color: transparent;
}

.cta-heading {
  font-size: 48px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 0px;
  margin-top: 12px;
  line-height: 1.2;
  background-color: transparent;
}

.cta-description {
  font-size: var(--font-lg);
  line-height: 1.6;
  color: #e5e7eb;
  margin-top: 8px;
  margin-bottom: 28px;
  background-color: transparent;
}

.demo-button {
  display: inline-flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  background-color: var(--primary-color);
  color: white;
  padding: 12px 24px;
  border-radius: 30px;
  text-decoration: none;
  transition: all 0.2s ease;
}

.demo-button:hover {
  background-color: var(--active-text);
  transform: translateY(-2px);
}

.arrow {
  background-color: transparent;
  font-size: 20px;
  color: #fff;
  transition: transform 0.2s ease;
}

.demo-button:hover .arrow {
  transform: translateX(4px);
}

/* Responsive Design */
@media (max-width: 1024px) {
  .cta-section {
    padding: 70px var(--spacing-20);
  }

  .cta-heading {
    font-size: 42px;
  }

  .cta-description {
    font-size: var(--font-md);
  }
}

@media (max-width: 768px) {
  .cta-section {
    padding: 60px var(--spacing-16);
    min-height: 350px;
  }



  .cta-heading {
    font-size: 36px;
  }

  .cta-description {
    font-size: var(--font-sm);
  }
}

@media (max-width: 480px) {
  .cta-section {
    padding: 40px var(--spacing-12);
    min-height: 300px;
  }

  .cta-heading {
    font-size: 28px;
  }

  .cta-subtitle {
    font-size: var(--font-sm);
  }

  .cta-description {
    font-size: var(--font-xs);
  }

  .demo-button {
    width: 100%;
    justify-content: center;
  }
}
.footer {
  position: relative;
  width: 100%;
  padding: 80px 60px 24px;
  background-image: url(/static/media/footerBg.db6b454e.png);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.footer-container {
  max-width: 1500px;
  margin: 0 auto;
  background-color: transparent;
}

.footer-grid {
  display: grid;
  grid-gap: 32px;
  gap: 32px;
}

@media (min-width: 768px) {
  .footer-grid {
    grid-template-columns: 5fr 1fr 1fr 1fr;
    background-color: transparent;
  }
}

.footer-logo-section {
  display: flex;
  flex-direction: column;
  grid-gap: 0px;
  gap: 0px;
  background-color: transparent;
}

.footer-logo {
  display: inline-block;
  height: 40px;
  width: auto;
}

.footer-address {
  font-size: 14px;
  color: #666;
  line-height: 1.5;
}
.mpt-0 {
  margin-top: 0px;
  padding-top: 0px;
}

.footer-company-name {
  font-weight: 600;
  margin-bottom: 4px;
}

.footer-nav-links {
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  gap: 12px;
}

.footer-link {
  color: #333;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  transition: color 0.2s ease;
  background-color: transparent;
}

.footer-link:hover {
  color: var(--primary-color);
}

.footer-contact-section {
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  gap: 12px;
}

.footer-contact-title {
  font-size: 14px;
  font-weight: 600;
  margin: 0px;
}

.footer-bottom-section {
  margin-top: 48px;
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
  background-color: transparent;
}

@media (min-width: 640px) {
  .footer-bottom-section {
    flex-direction: row;
    justify-content: space-between;
    background-color: transparent;
  }
}

.footer-copyright {
  font-size: 14px;
  color: #000000;
  background-color: transparent;
}

.footer-bottom-links {
  display: flex;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
  background-color: transparent;
}
.footer-underline-link {
  font-weight: 400;
  font-size: 14px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #000000;
}
.footer-underline-link:hover {
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
}
.footer-social-link {
  transition: color 0.2s ease;
  width: 42px;
  height: 42px;
  text-align: center;
  align-items: center;
  align-self: center;
  padding-top: 7px;
  background-color: #fff;
  overflow: hidden;
  border-radius: 100%;
  border: 1px solid #eeeeee;
}

.footer-social-link:hover {
  border: 1px solid #e2e1e1;
}

.dashboard {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 48px;
  gap: 48px;
  min-height: 100vh;
  background-color: #f5f5f5;
}

.dashboard-left {
  background-image: url(/static/media/p2.c2922796.png);
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
  margin-top: 14px;
  background-color: transparent;
}

.dashboard-left-content {
  margin-top: 100px;
  padding: 80px;
  background-color: transparent;
}

.platform-label {
  display: inline-block;
  color: var(--primary-color);
  font-size: var(--font-sm);
  margin-bottom: 0px;
  margin-top: 20px;
}

.dashboard-title {
  font-size: 64px;
  font-weight: 700;
  background-color: transparent;
  line-height: 1.1;
  margin-bottom: 0px;
  margin-top: 20px;
  color: var(--primary-text);
}

.dashboard-description {
  font-size: var(--font-lg);
  line-height: 1.6;
  color: var(--secondary-text);
  background-color: transparent;
  max-width: 600px;
}

.dashboard-right {
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 0px;
  overflow-x: hidden;
  background-color: transparent;
}

.dashboard-interface {
  transform: translateX(14px);
  width: 100%;
  height: auto;
  max-width: 800px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

@media (max-width: 1024px) {
  .dashboard {
    grid-template-columns: 1fr;
    grid-gap: var(--spacing-24);
    gap: var(--spacing-24);
  }

  .dashboard-left-content {
    margin-top: 60px;
    padding: var(--spacing-20);
  }

  .dashboard-title {
    font-size: 48px;
  }

  .dashboard-description {
    font-size: var(--font-md);
  }

  .dashboard-right {
    padding: 0 var(--spacing-20) var(--spacing-12) 0;
  }
}

@media (max-width: 768px) {
  .dashboard {
    padding: var(--spacing-16);
  }

  .dashboard-left-content {
    margin-top: 40px;
    padding: var(--spacing-16);
  }

  .dashboard-title {
    font-size: 40px;
  }

  .dashboard-description {
    font-size: var(--font-md);
  }

  .platform-label {
    font-size: var(--font-xs);
  }
}

@media (max-width: 480px) {
  .dashboard {
    padding: var(--spacing-12);
  }

  .dashboard-left-content {
    margin-top: 20px;
    padding: var(--spacing-12);
  }

  .dashboard-title {
    font-size: 32px;
  }

  .dashboard-description {
    font-size: var(--font-md);
  }

  .dashboard-right {
    padding: 0 var(--spacing-12) var(--spacing-12) 0;
  }
}

.tracking-section {
  background-color: #fff;
  width: 100%;
  padding: 80px 80px;
  border-top: 1px solid var(--border-color);
}

.tracking-container {
  max-width: 1500px;
  margin: 0 auto;
}

.tracking-header {
  text-align: center;
  margin-bottom: 64px;
}

.tracking-label {
  display: inline-block;
  padding: 4px 12px;
  background-color: #e6edff;
  color: var(--primary-color);
  border-radius: 16px;
  font-size: var(--font-sm);
  margin-bottom: 0px;
}

.tracking-title {
  font-size: 48px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 24px;
  color: var(--primary-text);
  margin-top: 16px;
}

.tracking-description {
  font-size: var(--font-lg);
  line-height: 1.6;
  color: var(--secondary-text);
  max-width: 800px;
  margin: 0 auto;
}

.feature-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 32px;
  gap: 32px;
}

.feature-card {
  background: #fff;
  border-radius: 16px;
  border: 1px solid var(--border-color);
}

.feature-card img {
  border-bottom: 1px solid var(--border-color);
  padding: 24px 24px 0px;
}

.feature-card-text {
  padding: 0px 24px 24px;
}

.h260 {
  height: 260px !important;
}

.feature-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 0px;
}

.feature-title {
  font-size: 24px;
  font-weight: 600;
  color: var(--primary-text);
  margin-bottom: 0px;
}

.feature-description {
  font-size: var(--font-md);
  line-height: 1.6;
  color: var(--secondary-text);
  margin-top: 12px;
}

@media (max-width: 1024px) {
  .tracking-section {
    padding: 60px var(--spacing-20);
  }

  .tracking-title {
    font-size: 42px;
  }

  .tracking-description {
    font-size: var(--font-md);
  }

  .feature-cards {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--spacing-20);
    gap: var(--spacing-20);
  }

  .feature-title {
    font-size: 22px;
  }
}

@media (max-width: 768px) {
  .tracking-section {
    padding: 48px var(--spacing-16);
  }

  .tracking-header {
    text-align: left;
    margin-bottom: var(--spacing-32);
  }

  .tracking-title {
    font-size: 36px;
  }

  .tracking-description {
    margin-left: 0;
    margin-right: 0;
  }

  .feature-cards {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .tracking-section {
    padding: 40px var(--spacing-12);
  }

  .tracking-title {
    font-size: 32px;
  }

  .tracking-label {
    font-size: var(--font-md);
  }

  .feature-card img {
    padding: var(--spacing-16) var(--spacing-16) 0;
  }

  .feature-card-text {
    padding: 0 var(--spacing-16) var(--spacing-16);
  }
  .hide-sm{
    display: none;
  }
}

.reports-section {
  background-color: #fff;
  width: 100%;
  padding: 80px 80px;
  border-top: 1px solid var(--border-color);
}

.reports-container {
  max-width: 1500px;
  margin: 0 auto;
}

.reports-header {
  margin-bottom: 64px;
}

.reports-label {
  display: inline-block;
  padding: 4px 12px;
  background-color: #e6edff;
  color: var(--primary-color);
  border-radius: 16px;
  font-size: var(--font-sm);
  margin-bottom: 0px;
}

.reports-title {
  font-size: 48px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 24px;
  color: var(--primary-text);
  margin-top: 16px;
  max-width: 800px;
}

.reports-description {
  font-size: var(--font-lg);
  line-height: 1.6;
  color: var(--secondary-text);
  max-width: 600px;
}

.reports-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 48px;
  gap: 48px;
  align-items: stretch;
}

.reports-interface {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #f7f7f9;
  border-radius: 12px;
  padding-left: 15%;
  height: 100%;
}

.interface-image {
  width: 100%;
  height: auto;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.report-types {
  display: flex;
  flex-direction: column;
  grid-gap: 63px;
  gap: 63px;
}

.report-card {
  border-radius: 12px;
  padding: 20px 24px 0px;
  border: 1px solid transparent;
  background-color: transparent;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
}

.report-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.report-card.active {
  background-color: #f7f7f9;
  border: 1px solid var(--border-color);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.report-card-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0px;
  color: var(--primary-text);
  margin-top: 8px;
}

.report-card-description {
  font-size: var(--font-md);
  line-height: 1.6;
  color: var(--secondary-text);
  margin-top: 8px;
}

@media (max-width: 1024px) {
  .reports-section {
    padding: 60px var(--spacing-20);
  }

  .reports-title {
    font-size: 42px;
  }

  .reports-description {
    font-size: var(--font-md);
  }

  .reports-content {
    grid-template-columns: 1fr;
    grid-gap: var(--spacing-32);
    gap: var(--spacing-32);
  }

  .reports-interface {
    order: 2;
  }

  .report-types {
    order: 1;
    grid-gap: var(--spacing-24);
    gap: var(--spacing-24);
  }

  .report-card-title {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .reports-section {
    padding: 48px var(--spacing-16);
  }

  .reports-header {
    text-align: left;
    margin-bottom: var(--spacing-32);
  }

  .reports-title {
    font-size: 36px;
  }

  .reports-description {
    max-width: 100%;
  }

  .report-types {
    grid-gap: var(--spacing-20);
    gap: var(--spacing-20);
  }
}

@media (max-width: 480px) {
  .reports-section {
    padding: 40px var(--spacing-12);
  }

  .reports-title {
    font-size: 32px;
  }

  .reports-label {
    font-size: var(--font-md);
  }

  .report-card {
    padding: var(--spacing-16) var(--spacing-16) 0;
  }
}
.project-schedule-section {
  padding: 80px 24px;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #f5f6f8;
}

.w-full {
  max-width: 100%;
}

.m-8-ve {
  margin-bottom: -8px;
}

.project-schedule-container {
  max-width: 900px;
  margin: 0 auto;
}

.project-platform-label {
  display: inline-block;
  padding: 4px 12px;
  background-color: transparent;
  color: var(--primary-color);
  border-radius: 16px;
  font-size: var(--font-sm);
  margin-bottom: 24px;
}

.project-schedule-title {
  font-size: 64px;
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 24px;
  color: var(--primary-text);
  margin-top: 0px;
}

.project-schedule-description {
  font-size: var(--font-lg);
  line-height: 1.6;
  color: var(--secondary-text);
  max-width: 800px;
  margin: 0 auto;
}

@media (max-width: 1024px) {
  .project-schedule-section {
    padding: 70px var(--spacing-20);
  }

  .project-schedule-title {
    font-size: 56px;
  }

  .project-schedule-description {
    font-size: var(--font-md);
  }
}

@media (max-width: 768px) {
  .project-schedule-section {
    padding: 60px var(--spacing-16);
    text-align: left;
  }

  .project-schedule-container {
    max-width: 100%;
  }

  .project-schedule-title {
    font-size: 48px;
  }

  .project-schedule-description {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 480px) {
  .project-schedule-section {
    padding: 48px var(--spacing-12);
  }

  .project-schedule-title {
    font-size: 40px;
    margin: 8px 0px;
  }

  .project-platform-label {
    font-size: var(--font-sm);
    margin-bottom: 12px;
    padding: 0px;
  }
}

.insights-section {
  width: 100%;
  padding: 80px 0px;
  background-color: #ffffff;
  border-top: 1px solid var(--border-color);
}

.insights-container {
  max-width: 1500px;
  margin: 0 auto;
}

.insights-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 64px;
  gap: 64px;
  align-items: center;
  padding: 0px 80px;
}

.insights-text {
  max-width: 700px;
}

.ai-label {
  display: inline-block;
  padding: 4px 12px;
  background-color: transparent;
  color: var(--primary-color);
  border-radius: 16px;
  font-size: var(--font-sm);
  margin-bottom: 0px;
}

.insights-title {
  font-weight: 600;
  font-size: 32px;
  line-height: 1.2;
  text-transform: uppercase;
  color: var(--primary-text);
  margin-top: var(--spacing-16);
  margin-bottom: var(--spacing-16);
}

.insights-description {
  font-weight: 400;
  font-size: var(--font-md);
  line-height: 1.5;
  color: var(--secondary-text);
}

.bt {
  border-top: 1px solid var(--border-color);
}

.insights-visual {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.insights-image {
  width: 100%;
  max-width: 700px;
  height: auto;
}

@media (max-width: 1024px) {
  .insights-section {
    padding: 60px 0px;
  }

  .insights-content {
    grid-template-columns: 1fr;
    grid-gap: var(--spacing-32);
    gap: var(--spacing-32);
    padding: 0px var(--spacing-20);
  }

  .insights-text {
    max-width: 100%;
  }

  .insights-visual {
    justify-content: center;
    order: -1;
  }

  .insights-title {
    font-size: 28px;
  }
}

@media (max-width: 768px) {
  .insights-section {
    padding: 48px 0px;
  }

  .insights-content {
    padding: 0px var(--spacing-16);
  }

  .insights-title {
    font-size: 24px;
  }

  .ai-label {
    font-size: var(--font-md);
    margin-bottom: var(--spacing-12);
    padding: 0px;
  }
}

@media (max-width: 480px) {
  .insights-section {
    padding: 40px 0px;
  }

  .insights-content {
    padding: 0px var(--spacing-12);
    grid-gap: var(--spacing-24);
    gap: var(--spacing-24);
  }

  .insights-title {
    font-size: 22px;
  }
}

.variation-section {
  padding: 80px 24px;
  background-color: #f9f9f9;
  min-height: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.variation-container {
  max-width: 900px;
  margin: 0 auto;
}

.tracker-label {
  display: inline-block;
  padding: 4px 12px;
  background-color: transparent;
  color: var(--primary-color);
  border-radius: 16px;
  font-size: var(--font-sm);
  margin-bottom: var(--spacing-24);
}

.variation-title {
  font-weight: 600;
  font-size: 32px;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
  color: var(--primary-text);
  margin-top: 0px;
  margin-bottom: var(--spacing-12);
}

.variation-subtitle {
  font-weight: 400;
  font-size: var(--font-lg);
  line-height: 1.5;
  text-align: center;
  color: var(--primary-text);
  max-width: 704px;
  margin: 0 auto;
}

.variation-dash {
  color: var(--secondary-text);
}

@media (max-width: 1024px) {
  .variation-section {
    padding: 70px var(--spacing-20);
  }

  .variation-title {
    font-size: 28px;
  }

  .variation-subtitle {
    font-size: var(--font-md);
  }
}

@media (max-width: 768px) {
  .variation-section {
    padding: 60px var(--spacing-16);
    text-align: left;
  }

  .variation-container {
    max-width: 100%;
  }

  .variation-title {
    font-size: 24px;
    text-align: left;
  }

  .variation-subtitle {
    text-align: left;
  }

  .tracker-label {
    font-size: var(--font-sm);
    padding: 0;
    margin-bottom: var(--spacing-20);
  }
}

@media (max-width: 480px) {
  .variation-section {
    padding: 48px var(--spacing-12);
  }

  .variation-title {
    font-size: 22px;
  }
}

.documents-section {
  padding: 80px 0px 80px 80px;
  background-color: #f5f6f8;
}

.documents-container {
  max-width: 1500px;
  margin: 0 auto;
}

.documents-content {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 0px;
  gap: 0px;
  align-items: center;
}

.documents-text {
  max-width: 520px;
}

.platform-label {
  display: inline-block;
  padding: 4px 12px;
  background-color: transparent;
  color: var(--primary-color);
  border-radius: 16px;
  font-size: var(--font-sm);
  margin-bottom: 0px;
}

.documents-title {
  font-weight: 900;
  font-size: 60px;
  line-height: 1.08;
  color: var(--primary-text);
  white-space: nowrap;
  margin: var(--spacing-8) auto;
}

.documents-description {
  font-weight: 400;
  font-size: var(--font-md);
  line-height: 1.5;
  color: var(--secondary-text);
}

.documents-visual {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.pd-interface-image {
  width: 100%;
  height: auto;
}

@media (max-width: 1024px) {
  .documents-section {
    padding: 70px var(--spacing-20);
  }

  .documents-content {
    grid-template-columns: 1fr;
    grid-gap: var(--spacing-48);
    gap: var(--spacing-48);
  }

  .documents-text {
    max-width: 100%;
  }

  .documents-visual {
    justify-content: center;
    order: -1;
  }

  .documents-title {
    font-size: 48px;
    white-space: normal;
  }

  .pd-interface-image {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .documents-section {
    padding: 60px var(--spacing-16);
  }

  .documents-title {
    font-size: 40px;
  }

  .documents-description {
    font-size: var(--font-md);
  }

  .platform-label {
    padding: 0;
    margin-bottom: var(--spacing-12);
  }
}

@media (max-width: 480px) {
  .documents-section {
    padding: 48px var(--spacing-12);
  }

  .documents-title {
    font-size: 32px;
  }
}
.ai-section {
  padding: 80px 0px 40px;
  background-color: var(--background-white);
}

.ai-container {
  max-width: 1500px;
  margin: 0 auto;
}

.ai-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: var(--spacing-64);
  gap: var(--spacing-64);
  align-items: center;
  padding: 0px 80px;
}

.ai-visual {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.dai-interface-image {
  width: 100%;
  max-width: 700px;
  height: auto;
}

.ai-text {
  max-width: 520px;
}

.ai-label {
  display: inline-block;
  padding: 4px 12px;
  background-color: #e6edff;
  color: var(--primary-color);
  border-radius: 16px;
  font-size: var(--font-sm);
  margin-bottom: var(--spacing-24);
}

.ai-title {
  font-weight: 600;
  font-size: 32px;
  line-height: 1.2;
  text-transform: uppercase;
  color: var(--primary-text);
  margin: var(--spacing-12) 0px;
}

.mt-24 {
  margin-top: var(--spacing-40);
}

.ai-description {
  font-weight: 400;
  font-size: var(--font-md);
  line-height: 1.5;
  color: var(--primary-text);
}

.ul {
  margin: 0px;
  margin-left: -12px;
  padding-left: var(--spacing-24);
}

.bt {
  border-top: 1px solid var(--border-color);
  margin-top: var(--spacing-40);
  padding-top: var(--spacing-40);
}

@media (max-width: 1024px) {
  .ai-section {
    padding: 70px var(--spacing-20);
  }

  .ai-content {
    grid-template-columns: 1fr;
    grid-gap: var(--spacing-48);
    gap: var(--spacing-48);
    padding: 0;
  }

  .ai-text {
    max-width: 100%;
  }

  .ai-visual {
    justify-content: center;
  }

  .dai-interface-image {
    max-width: 100%;
  }

  .ai-title {
    font-size: 28px;
  }
}

@media (max-width: 768px) {
  .ai-section {
    padding: 60px var(--spacing-16);
  }

  .ai-title {
    font-size: 24px;
  }

  .ai-description {
    font-size: var(--font-md);
  }

  .ai-label {
    font-size: var(--font-md);
    margin-bottom: var(--spacing-12);
    padding: 0px;
  }

  .mt-24 {
    margin-top: var(--spacing-24);
  }

  .bt {
    margin-top: var(--spacing-32);
    padding-top: var(--spacing-32);
  }
}

@media (max-width: 480px) {
  .ai-section {
    padding: 48px var(--spacing-12);
  }

  .ai-title {
    font-size: 22px;
  }
}

.coming-section {
  padding: 80px var(--spacing-80) 40px;
  background-color: var(--background-light);
}

.coming-container {
  max-width: 1500px;
  margin: 0 auto;
}

.coming-title {
  font-weight: 600;
  font-size: 32px;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
  color: var(--primary-text);
  margin-bottom: var(--spacing-40);
}

.feature-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: var(--spacing-32);
  gap: var(--spacing-32);
}

.cs-feature-card {
  background: var(--background-white);
  border-radius: 16px;
  padding: var(--spacing-24) 0px;
  border: 1px solid var(--border-color);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.cs-feature-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.feature-image-container {
  margin-bottom: var(--spacing-24);
  border-radius: 8px;
  overflow: hidden;
}

.feature-image {
  width: 100%;
  height: auto;
  display: block;
}

.feature-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 1.3;
  color: var(--primary-text);
  margin-bottom: var(--spacing-12);
}

.cs-feature-card-container {
  padding: 0px var(--spacing-24);
}

.feature-description {
  font-weight: 400;
  font-size: var(--font-md);
  line-height: 1.5;
  color: var(--secondary-text);
  max-width: 576px;
}

@media (max-width: 1024px) {
  .coming-section {
    padding: 70px var(--spacing-20) 40px;
  }

  .coming-title {
    font-size: 28px;
  }

  .feature-title {
    font-size: 28px;
  }
}

@media (max-width: 768px) {
  .coming-section {
    padding: 60px var(--spacing-16) 40px;
  }

  .coming-title {
    font-size: 24px;
    margin-bottom: var(--spacing-32);
  }

  .feature-grid {
    grid-template-columns: 1fr;
    grid-gap: var(--spacing-24);
    gap: var(--spacing-24);
  }

  .feature-title {
    font-size: 24px;
  }
}

@media (max-width: 480px) {
  .coming-section {
    padding: 48px var(--spacing-12) 32px;
  }

  .coming-title {
    font-size: 22px;
  }

  .feature-title {
    font-size: 20px;
  }

  .cs-feature-card {
    padding: var(--spacing-16) 0px;
  }

  .cs-feature-card-container {
    padding: 0px var(--spacing-16);
  }
}

.solutions-section {
  padding: var(--spacing-80) var(--spacing-24);
  background-color: var(--background-white);
  text-align: center;
}

.solutions-container {
  max-width: 1500px;
  margin: 0 auto;
}

.solutions-label {
  display: inline-block;
  padding: 4px var(--spacing-12);
  background-color: transparent;
  color: var(--primary-color);
  border-radius: 16px;
  font-size: var(--font-sm);
  margin-bottom: 0;
}

.solutions-title {
  font-weight: 900;
  font-size: 74px;
  line-height: 1.08;
  text-align: center;
  color: var(--primary-text);
  margin-bottom: 0;
  margin-top: var(--spacing-12);
}

.solutions-description {
  font-weight: 400;
  font-size: var(--font-md);
  line-height: 1.5;
  text-align: center;
  color: var(--secondary-text);
  max-width: 976px;
  margin: var(--spacing-12) auto var(--spacing-16);
}

.demo-button {
  display: inline-flex;
  align-items: center;
  grid-gap: var(--spacing-8);
  gap: var(--spacing-8);
  padding: var(--spacing-12) var(--spacing-24);
  background-color: var(--primary-color);
  color: var(--background-white);
  border: none;
  border-radius: 30px;
  font-size: var(--font-md);
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
  margin-bottom: var(--spacing-48);
  text-decoration: none !important;
}

.demo-button:hover {
  background-color: var(--primary-hover);
}

.arrow {
  font-size: 20px;
  transition: transform 0.2s ease;
}

.demo-button:hover .arrow {
  transform: translateX(4px);
}

.video-container {
  position: relative;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.demo-video {
  width: 100%;
  aspect-ratio: 16 / 9;
  border: none;
}

@media (max-width: 1024px) {
  .solutions-section {
    padding: 70px var(--spacing-24);
  }

  .solutions-title {
    font-size: 60px;
  }
}

@media (max-width: 768px) {
  .solutions-section {
    padding: 60px var(--spacing-16);
    text-align: left;
  }

  .solutions-container {
    max-width: 100%;
  }

  .solutions-title {
    font-size: 48px;
    text-align: left;
  }

  .solutions-description {
    font-size: var(--font-md);
    text-align: left;
    margin-left: 0;
    margin-right: 0;
  }

  .solutions-label {
    padding: 0;
    margin-bottom: var(--spacing-12);
  }

  .demo-button {
    margin-bottom: var(--spacing-24);
  }
}

@media (max-width: 480px) {
  .solutions-section {
    padding: 48px var(--spacing-12);
  }

  .solutions-title {
    font-size: 36px;
  }

  .solutions-label {
    font-size: var(--font-md);
  }

  .demo-button {
    padding: var(--spacing-8) var(--spacing-16);
    font-size: var(--font-sm);
  }
}



.serve-section {
  padding: var(--spacing-80);
  background-color: var(--background-white);
}

.serve-container {
  max-width: 1500px;
  margin: 0 auto;
}

.serve-header {
  text-align: center;
  margin-bottom: var(--spacing-64);
}

.serve-title {
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  text-transform: uppercase;
  color: var(--primary-text);
}

.serve-description {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  text-align: center;
  color: var(--primary-text);
  max-width: 704px;
  margin: var(--spacing-12) auto;
}

.main-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: var(--spacing-32);
  gap: var(--spacing-32);
  margin-bottom: var(--spacing-64);
}

.main-card {
  background: var(--background-white);
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.card-image {
  width: 100%;
  height: 300px;
  overflow: hidden;
}

.contractor-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-title {
  margin: var(--spacing-24) var(--spacing-24) var(--spacing-12);
  font-weight: 700;
  font-size: 20px;
  line-height: 1.4;
  color: var(--primary-text);
}

.card-description {
  margin: 0 var(--spacing-24) var(--spacing-24);
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: var(--primary-text);
}

.service-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: var(--spacing-32);
  gap: var(--spacing-32);
}

.service-card {
  padding: var(--spacing-32) var(--spacing-32) var(--spacing-12);
  background: var(--background-white);
  border-radius: 16px;
  border: 1px solid var(--border-color);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.service-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.service-icon {
  width: 48px;
  height: 48px;
  background: #e6edff;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--spacing-24);
}

.icon {
  width: 24px;
  height: 24px;
  color: var(--primary-color);
}

.service-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.4;
  color: var(--primary-text);
  margin: var(--spacing-8) auto;
}

.service-description {
  margin: var(--spacing-8) auto;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: var(--primary-text);
}

@media (max-width: 1024px) {
  .service-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .serve-section {
    padding: 60px var(--spacing-24);
  }

  .serve-header {
    text-align: left;
  }

  .serve-title {
    font-size: 40px;
    text-align: left;
  }

  .serve-description {
    font-size: var(--font-md);
    text-align: left;
    margin-left: 0;
    margin-right: 0;
  }

  .main-cards {
    grid-template-columns: 1fr;
  }

  .service-cards {
    grid-template-columns: 1fr;
  }

  .card-title,
  .card-description,
  .service-title,
  .service-description {
    text-align: left;
  }
}

@media (max-width: 480px) {
  .serve-section {
    padding: 48px var(--spacing-16);
  }

  .serve-title {
    font-size: 32px;
  }

  .card-image {
    height: 200px;
  }
}
.privacy-container {
  padding: 48px 24px;
  background-color: #fff;
  min-height: 100vh;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.privacy-content {
  max-width: 800px;
  margin: 0 auto;
}

.privacy-container h1 {
  font-size: 32px;
  font-weight: 700;
  color: #000;
  margin-bottom: 24px;
}

.privacy-container h2 {
  font-size: 24px;
  font-weight: 600;
  color: #000;
  margin: 32px 0 16px;
}

.privacy-container p {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  margin-bottom: 16px;
}

.privacy-container section {
  margin-bottom: 32px;
}

.privacy-container ul {
  list-style-type: disc;
  padding-left: 24px;
  margin-bottom: 16px;
}

.privacy-container li {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  margin-bottom: 8px;
}

.privacy-container a {
  color: var(--primary-color);
  text-decoration: none;
  transition: color 0.2s ease;
}

.privacy-container a:hover {
  color: #0052cc;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .privacy-container {
    padding: 32px 16px;
  }

  .privacy-container h1 {
    font-size: 28px;
  }

  .privacy-container h2 {
    font-size: 20px;
  }

  .privacy-container p,
  .privacy-container li {
    font-size: 15px;
  }
}

@media (max-width: 480px) {
  .privacy-container {
    padding: 24px 16px;
  }

  .privacy-container h1 {
    font-size: 24px;
  }

  .privacy-container h2 {
    font-size: 18px;
  }
}

.terms-container {
  padding: 48px 24px;
  background-color: #fff;
  min-height: 100vh;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.terms-content {
  max-width: 800px;
  margin: 0 auto;
}

.terms-container h1 {
  font-size: 32px;
  font-weight: 700;
  color: #000;
  margin-bottom: 24px;
}

.welcome {
  font-size: 20px;
  font-weight: 500;
  color: #333;
  margin-bottom: 24px;
}

.terms-container h2 {
  font-size: 24px;
  font-weight: 600;
  color: #000;
  margin: 32px 0 16px;
}

.terms-container p {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  margin-bottom: 16px;
}

.terms-container section {
  margin-bottom: 32px;
}

.terms-container ul {
  list-style-type: disc;
  padding-left: 24px;
  margin: 16px 0;
}

.terms-container li {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  margin-bottom: 8px;
}

.terms-container a {
  color: var(--primary-color);
  text-decoration: none;
  transition: color 0.2s ease;
}

.terms-container a:hover {
  color: #0052cc;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .terms-container {
    padding: 32px 16px;
  }

  .terms-container h1 {
    font-size: 28px;
  }

  .welcome {
    font-size: 18px;
  }

  .terms-container h2 {
    font-size: 20px;
  }

  .terms-container p,
  .terms-container li {
    font-size: 15px;
  }
}

@media (max-width: 480px) {
  .terms-container {
    padding: 24px 16px;
  }

  .terms-container h1 {
    font-size: 24px;
  }

  .terms-container h2 {
    font-size: 18px;
  }
}

.ScheduleDemoContainer {
  height: 100vh;
  margin: 0 auto;
  background-color: var(--background-white);
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.ScheduleDemoContainer iframe {
  border: none;
  background: transparent;
  scrollbar-width: none; /* Hides scrollbar in Firefox */
  -ms-overflow-style: none; /* Hides scrollbar in IE/Edge */
}

.ScheduleDemoContainer iframe::-webkit-scrollbar {
  display: none; /* Hides scrollbar in Chrome/Safari */
}

.TCECaseStudy-section {
  padding: 80px 24px;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #f5f6f8;
}

.w-full {
  max-width: 100%;
}

.m-8-ve {
  margin-bottom: -8px;
}

.TCECaseStudy-container {
  max-width: 900px;
  margin: 0 auto;
}

.TCECaseStudy-label {
  display: inline-block;
  padding: 4px 12px;
  background-color: transparent;
  color: var(--primary-color);
  border-radius: 16px;
  font-size: var(--font-sm);
  margin-bottom: 24px;
}

.TCECaseStudy-title {
  font-size: 48px;
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 24px;
  color: var(--primary-text);
  margin-top: 0px;
}

.TCECaseStudy-description {
  font-size: var(--font-lg);
  line-height: 1.6;
  color: var(--secondary-text);
  max-width: 800px;
  margin: 0 auto;
}
.tce-img {
  mix-blend-mode: darken;
  max-width: 40%;
  margin: 1rem auto;
}
.evaluation-content .feature-card img {
  padding: 0px;
}
@media (max-width: 1024px) {
  .TCECaseStudy-section {
    padding: 70px var(--spacing-20);
  }

  .TCECaseStudy-title {
    font-size: 56px;
  }

  .TCECaseStudy-description {
    font-size: var(--font-md);
  }
}

@media (max-width: 768px) {
  .TCECaseStudy-section {
    padding: 60px var(--spacing-16);
    text-align: center;
  }

  .TCECaseStudy-container {
    max-width: 100%;
  }

  .TCECaseStudy-title {
    font-size: 48px;
  }

  .TCECaseStudy-description {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 480px) {
  .TCECaseStudy-section {
    padding: 48px var(--spacing-12);
  }

  .TCECaseStudy-title {
    font-size: 40px;
    margin: 8px 0px;
  }

  .TCECaseStudy-label {
    font-size: var(--font-sm);
    margin-bottom: 12px;
    padding: 0px;
  }
}

.summary-section {
  background-color: #fff;
  width: 100%;
  padding: 80px 80px;
  border-top: 1px solid var(--border-color);
}

.summary-container {
  max-width: 1500px;
  margin: 0 auto;
}

.summary-header {
  margin-bottom: 64px;
}

.summary-label {
  display: inline-block;
  padding: 4px 12px;
  background-color: #e6edff;
  color: var(--primary-color);
  border-radius: 16px;
  font-size: var(--font-sm);
  margin-bottom: 0px;
}

.summary-title {
  font-size: 48px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 24px;
  color: var(--primary-text);
  margin-top: 16px;
}

.summary-description {
  font-size: var(--font-lg);
  line-height: 1.6;
  color: var(--secondary-text);
}

@media (max-width: 1024px) {
  .summary-section {
    padding: 60px var(--spacing-20);
  }

  .summary-title {
    font-size: 42px;
  }

  .summary-description {
    font-size: var(--font-md);
  }
}

@media (max-width: 768px) {
  .summary-section {
    padding: 48px var(--spacing-16);
  }

  .summary-header {
    text-align: left;
    margin-bottom: var(--spacing-32);
  }

  .summary-title {
    font-size: 36px;
  }

  .summary-description {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .summary-section {
    padding: 40px var(--spacing-12);
  }

  .summary-title {
    font-size: 32px;
  }

  .summary-label {
    font-size: var(--font-md);
  }
}

.evaluation-container {
  width: 100%;
  background-color: #ffffff;
  padding: 120px 80px 80px;
  border-top: 1px solid var(--border-color);
}

.evaluation-content {
  max-width: 1500px;
  margin: 0 auto;
}

.evaluation-header {
  text-align: center;
  margin-bottom: 48px;
}

.badge {
  display: inline-block;
  padding: 6px 12px;
  background-color: #e8f0ff;
  color: var(--primary-color);
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 24px;
}

.evaluation-header h2 {
  font-size: 40px;
  font-weight: 700;
  color: var(--primary-text);
  margin-bottom: 20px;
  line-height: 1.2;
  background-color: transparent;
}

.evaluation-header .description {
  font-size: 18px;
  line-height: 1.6;
  color: var(--secondary-text);
  max-width: 800px;
  margin: 0 auto;
  background-color: transparent;
  text-align: center;
}

.feature-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
  gap: 24px;
}

.feature-card {
  border-radius: 12px;
  padding: 32px 28px;
  transition: transform 0.2s ease;
}

.feature-card:hover {
  transform: translateY(-4px);
}

.icon-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  border: 1px solid var(--border-color);
  color: var(--primary-color);
}

.feature-card h3 {
  font-size: 24px;
  font-weight: 600;
  color: var(--primary-text);
  margin: 8px 0px;
}

.feature-card p {
  font-size: 16px;
  line-height: 1.6;
  color: var(--secondary-text);
  margin: 0px;
}
.chart-container {
  width: 100%;
  height: 500px;
  margin-top: 3rem;
}
.chart-container p {
  text-align: center;
  margin-bottom: -10px;
}

.operations-cards {
  grid-template-columns: repeat(4, 1fr) !important;
}
.operations-cards .feature-card h3{
  text-align: left !important;
}

.operations-cards h2 {
  font-size: 48px;
  color: var(--primary-color);
  margin: 0px;
}
/* Responsive Design */
@media (max-width: 1024px) {
  .evaluation-container {
    padding: 80px var(--spacing-20);
  }

  .evaluation-header h2 {
    font-size: 36px;
  }

  .description {
    font-size: var(--font-md);
    max-width: 90%;
  }

  .feature-card {
    padding: var(--spacing-24);
  }

  .feature-card h3 {
    font-size: 22px;
  }

  .feature-card p {
    font-size: var(--font-sm);
  }
}

@media (max-width: 768px) {
  .evaluation-container {
    padding: 60px var(--spacing-16);
  }

  .evaluation-header {
    text-align: left;
  }

  .description {
    text-align: left;
    margin-left: 0;
    margin-right: auto;
  }

  .feature-cards {
    grid-template-columns: 1fr;
    grid-gap: var(--spacing-20);
    gap: var(--spacing-20);
  }
  .operations-cards {
    grid-template-columns: 1fr;
  }

  .evaluation-header h2 {
    font-size: 32px;
  }

  .feature-card h3 {
    font-size: 20px;
  }

  .description {
    font-size: var(--font-sm);
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .evaluation-container {
    padding: 40px var(--spacing-12);
  }

  .evaluation-header h2 {
    font-size: 28px;
  }

  .badge {
    font-size: var(--font-xs);
    padding: var(--spacing-4) var(--spacing-8);
  }

  .feature-card {
    padding: var(--spacing-20);
  }

  .feature-card h3 {
    font-size: 18px;
  }

  .feature-card p {
    font-size: var(--font-md);
  }

  .icon-wrapper {
    width: 40px;
    height: 40px;
  }
  .evaluation-header .description {
    text-align: left;
    font-size: var(--font-md);
}
}

/* General Styles */
.timeline-container {
  width: 90%;
  max-width: 1200px;
  height: auto;
  margin: 1% auto 7%;
  padding: 20px;
}

.timeline-rb-container {
  font-family: "PT Sans", sans-serif;
  width: 100%;
  margin: auto;
  position: relative;
}

.timeline-rb {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.timeline-rb-item {
  display: flex;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
  position: relative;
  padding-left: 30px;
  border-left: 4px solid var(--primary-color);
  max-width: 240px;
  height: 120px;
}

.timeline-rb-item::before {
  content: "";
  position: absolute;
  left: -14px;
  width: 24px;
  height: 24px;
  background: var(--primary-hover);
  border-radius: 50%;
  border: 2px solid white;
  z-index: 2;
}
.timeline-rb-item::after {
  z-index: 1;
  content: "";
  position: absolute;
  left: -19px;
  width: 36px;
  height: 36px;
  background: white;
  border-radius: 50%;
  border: 2px solid var(--primary-hover);
}
.timeline-rb-item1::before {
  background: #e87d1f !important;
}
.timeline-rb-item1::after {
  border: 2px solid #e87d1f !important;
}

.timeline-timestamp {
  color: var(--primary-text);
  font-size: 16px;
  white-space: nowrap;
}

.timeline-item-title {
  color: #fff;
}

/* Responsive Design */
@media (min-width: 768px) {
  .timeline-rb {
    flex-direction: row;
  }
  .timeline-timestamp {
    max-width: 92px;
    white-space: pre-wrap;
  }
  .timeline-rb-item {
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-left: none;
    border-top: 4px solid hsla(219, 100%, 40%, 0.5);
    padding-left: 0;
    padding-top: 30px;
    width: 240px;
    height: 0px;
  }
  .timeline-rb-item::before {
    top: -14px;
    left: 50%;
    transform: translateX(-50%);
  }
  .timeline-rb-item::after {
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

