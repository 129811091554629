.footer {
  position: relative;
  width: 100%;
  padding: 80px 60px 24px;
  background-image: url("../../assets/footerBg.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.footer-container {
  max-width: 1500px;
  margin: 0 auto;
  background-color: transparent;
}

.footer-grid {
  display: grid;
  gap: 32px;
}

@media (min-width: 768px) {
  .footer-grid {
    grid-template-columns: 5fr 1fr 1fr 1fr;
    background-color: transparent;
  }
}

.footer-logo-section {
  display: flex;
  flex-direction: column;
  gap: 0px;
  background-color: transparent;
}

.footer-logo {
  display: inline-block;
  height: 40px;
  width: auto;
}

.footer-address {
  font-size: 14px;
  color: #666;
  line-height: 1.5;
}
.mpt-0 {
  margin-top: 0px;
  padding-top: 0px;
}

.footer-company-name {
  font-weight: 600;
  margin-bottom: 4px;
}

.footer-nav-links {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.footer-link {
  color: #333;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  transition: color 0.2s ease;
  background-color: transparent;
}

.footer-link:hover {
  color: var(--primary-color);
}

.footer-contact-section {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.footer-contact-title {
  font-size: 14px;
  font-weight: 600;
  margin: 0px;
}

.footer-bottom-section {
  margin-top: 48px;
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  background-color: transparent;
}

@media (min-width: 640px) {
  .footer-bottom-section {
    flex-direction: row;
    justify-content: space-between;
    background-color: transparent;
  }
}

.footer-copyright {
  font-size: 14px;
  color: #000000;
  background-color: transparent;
}

.footer-bottom-links {
  display: flex;
  align-items: center;
  gap: 16px;
  background-color: transparent;
}
.footer-underline-link {
  font-weight: 400;
  font-size: 14px;
  text-decoration-line: underline;
  color: #000000;
}
.footer-underline-link:hover {
  text-decoration-line: none;
}
.footer-social-link {
  transition: color 0.2s ease;
  width: 42px;
  height: 42px;
  text-align: center;
  align-items: center;
  align-self: center;
  padding-top: 7px;
  background-color: #fff;
  overflow: hidden;
  border-radius: 100%;
  border: 1px solid #eeeeee;
}

.footer-social-link:hover {
  border: 1px solid #e2e1e1;
}
