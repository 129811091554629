.TCECaseStudy-section {
  padding: 80px 24px;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #f5f6f8;
}

.w-full {
  max-width: 100%;
}

.m-8-ve {
  margin-bottom: -8px;
}

.TCECaseStudy-container {
  max-width: 900px;
  margin: 0 auto;
}

.TCECaseStudy-label {
  display: inline-block;
  padding: 4px 12px;
  background-color: transparent;
  color: var(--primary-color);
  border-radius: 16px;
  font-size: var(--font-sm);
  margin-bottom: 24px;
}

.TCECaseStudy-title {
  font-size: 48px;
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 24px;
  color: var(--primary-text);
  margin-top: 0px;
}

.TCECaseStudy-description {
  font-size: var(--font-lg);
  line-height: 1.6;
  color: var(--secondary-text);
  max-width: 800px;
  margin: 0 auto;
}
.tce-img {
  mix-blend-mode: darken;
  max-width: 40%;
  margin: 1rem auto;
}
.evaluation-content .feature-card img {
  padding: 0px;
}
@media (max-width: 1024px) {
  .TCECaseStudy-section {
    padding: 70px var(--spacing-20);
  }

  .TCECaseStudy-title {
    font-size: 56px;
  }

  .TCECaseStudy-description {
    font-size: var(--font-md);
  }
}

@media (max-width: 768px) {
  .TCECaseStudy-section {
    padding: 60px var(--spacing-16);
    text-align: center;
  }

  .TCECaseStudy-container {
    max-width: 100%;
  }

  .TCECaseStudy-title {
    font-size: 48px;
  }

  .TCECaseStudy-description {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 480px) {
  .TCECaseStudy-section {
    padding: 48px var(--spacing-12);
  }

  .TCECaseStudy-title {
    font-size: 40px;
    margin: 8px 0px;
  }

  .TCECaseStudy-label {
    font-size: var(--font-sm);
    margin-bottom: 12px;
    padding: 0px;
  }
}

.summary-section {
  background-color: #fff;
  width: 100%;
  padding: 80px 80px;
  border-top: 1px solid var(--border-color);
}

.summary-container {
  max-width: 1500px;
  margin: 0 auto;
}

.summary-header {
  margin-bottom: 64px;
}

.summary-label {
  display: inline-block;
  padding: 4px 12px;
  background-color: #e6edff;
  color: var(--primary-color);
  border-radius: 16px;
  font-size: var(--font-sm);
  margin-bottom: 0px;
}

.summary-title {
  font-size: 48px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 24px;
  color: var(--primary-text);
  margin-top: 16px;
}

.summary-description {
  font-size: var(--font-lg);
  line-height: 1.6;
  color: var(--secondary-text);
}

@media (max-width: 1024px) {
  .summary-section {
    padding: 60px var(--spacing-20);
  }

  .summary-title {
    font-size: 42px;
  }

  .summary-description {
    font-size: var(--font-md);
  }
}

@media (max-width: 768px) {
  .summary-section {
    padding: 48px var(--spacing-16);
  }

  .summary-header {
    text-align: left;
    margin-bottom: var(--spacing-32);
  }

  .summary-title {
    font-size: 36px;
  }

  .summary-description {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .summary-section {
    padding: 40px var(--spacing-12);
  }

  .summary-title {
    font-size: 32px;
  }

  .summary-label {
    font-size: var(--font-md);
  }
}

.evaluation-container {
  width: 100%;
  background-color: #ffffff;
  padding: 120px 80px 80px;
  border-top: 1px solid var(--border-color);
}

.evaluation-content {
  max-width: 1500px;
  margin: 0 auto;
}

.evaluation-header {
  text-align: center;
  margin-bottom: 48px;
}

.badge {
  display: inline-block;
  padding: 6px 12px;
  background-color: #e8f0ff;
  color: var(--primary-color);
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 24px;
}

.evaluation-header h2 {
  font-size: 40px;
  font-weight: 700;
  color: var(--primary-text);
  margin-bottom: 20px;
  line-height: 1.2;
  background-color: transparent;
}

.evaluation-header .description {
  font-size: 18px;
  line-height: 1.6;
  color: var(--secondary-text);
  max-width: 800px;
  margin: 0 auto;
  background-color: transparent;
  text-align: center;
}

.feature-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
}

.feature-card {
  border-radius: 12px;
  padding: 32px 28px;
  transition: transform 0.2s ease;
}

.feature-card:hover {
  transform: translateY(-4px);
}

.icon-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  border: 1px solid var(--border-color);
  color: var(--primary-color);
}

.feature-card h3 {
  font-size: 24px;
  font-weight: 600;
  color: var(--primary-text);
  margin: 8px 0px;
}

.feature-card p {
  font-size: 16px;
  line-height: 1.6;
  color: var(--secondary-text);
  margin: 0px;
}
.chart-container {
  width: 100%;
  height: 500px;
  margin-top: 3rem;
}
.chart-container p {
  text-align: center;
  margin-bottom: -10px;
}

.operations-cards {
  grid-template-columns: repeat(4, 1fr) !important;
}
.operations-cards .feature-card h3{
  text-align: left !important;
}

.operations-cards h2 {
  font-size: 48px;
  color: var(--primary-color);
  margin: 0px;
}
/* Responsive Design */
@media (max-width: 1024px) {
  .evaluation-container {
    padding: 80px var(--spacing-20);
  }

  .evaluation-header h2 {
    font-size: 36px;
  }

  .description {
    font-size: var(--font-md);
    max-width: 90%;
  }

  .feature-card {
    padding: var(--spacing-24);
  }

  .feature-card h3 {
    font-size: 22px;
  }

  .feature-card p {
    font-size: var(--font-sm);
  }
}

@media (max-width: 768px) {
  .evaluation-container {
    padding: 60px var(--spacing-16);
  }

  .evaluation-header {
    text-align: left;
  }

  .description {
    text-align: left;
    margin-left: 0;
    margin-right: auto;
  }

  .feature-cards {
    grid-template-columns: 1fr;
    gap: var(--spacing-20);
  }
  .operations-cards {
    grid-template-columns: 1fr;
  }

  .evaluation-header h2 {
    font-size: 32px;
  }

  .feature-card h3 {
    font-size: 20px;
  }

  .description {
    font-size: var(--font-sm);
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .evaluation-container {
    padding: 40px var(--spacing-12);
  }

  .evaluation-header h2 {
    font-size: 28px;
  }

  .badge {
    font-size: var(--font-xs);
    padding: var(--spacing-4) var(--spacing-8);
  }

  .feature-card {
    padding: var(--spacing-20);
  }

  .feature-card h3 {
    font-size: 18px;
  }

  .feature-card p {
    font-size: var(--font-md);
  }

  .icon-wrapper {
    width: 40px;
    height: 40px;
  }
  .evaluation-header .description {
    text-align: left;
    font-size: var(--font-md);
}
}

/* General Styles */
.timeline-container {
  width: 90%;
  max-width: 1200px;
  height: auto;
  margin: 1% auto 7%;
  padding: 20px;
}

.timeline-rb-container {
  font-family: "PT Sans", sans-serif;
  width: 100%;
  margin: auto;
  position: relative;
}

.timeline-rb {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.timeline-rb-item {
  display: flex;
  align-items: center;
  gap: 15px;
  position: relative;
  padding-left: 30px;
  border-left: 4px solid var(--primary-color);
  max-width: 240px;
  height: 120px;
}

.timeline-rb-item::before {
  content: "";
  position: absolute;
  left: -14px;
  width: 24px;
  height: 24px;
  background: var(--primary-hover);
  border-radius: 50%;
  border: 2px solid white;
  z-index: 2;
}
.timeline-rb-item::after {
  z-index: 1;
  content: "";
  position: absolute;
  left: -19px;
  width: 36px;
  height: 36px;
  background: white;
  border-radius: 50%;
  border: 2px solid var(--primary-hover);
}
.timeline-rb-item1::before {
  background: #e87d1f !important;
}
.timeline-rb-item1::after {
  border: 2px solid #e87d1f !important;
}

.timeline-timestamp {
  color: var(--primary-text);
  font-size: 16px;
  white-space: nowrap;
}

.timeline-item-title {
  color: #fff;
}

/* Responsive Design */
@media (min-width: 768px) {
  .timeline-rb {
    flex-direction: row;
  }
  .timeline-timestamp {
    max-width: 92px;
    white-space: pre-wrap;
  }
  .timeline-rb-item {
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-left: none;
    border-top: 4px solid hsla(219, 100%, 40%, 0.5);
    padding-left: 0;
    padding-top: 30px;
    width: 240px;
    height: 0px;
  }
  .timeline-rb-item::before {
    top: -14px;
    left: 50%;
    transform: translateX(-50%);
  }
  .timeline-rb-item::after {
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
  }
}
