.documents-section {
  padding: 80px 0px 80px 80px;
  background-color: #f5f6f8;
}

.documents-container {
  max-width: 1500px;
  margin: 0 auto;
}

.documents-content {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 0px;
  align-items: center;
}

.documents-text {
  max-width: 520px;
}

.platform-label {
  display: inline-block;
  padding: 4px 12px;
  background-color: transparent;
  color: var(--primary-color);
  border-radius: 16px;
  font-size: var(--font-sm);
  margin-bottom: 0px;
}

.documents-title {
  font-weight: 900;
  font-size: 60px;
  line-height: 1.08;
  color: var(--primary-text);
  white-space: nowrap;
  margin: var(--spacing-8) auto;
}

.documents-description {
  font-weight: 400;
  font-size: var(--font-md);
  line-height: 1.5;
  color: var(--secondary-text);
}

.documents-visual {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.pd-interface-image {
  width: 100%;
  height: auto;
}

@media (max-width: 1024px) {
  .documents-section {
    padding: 70px var(--spacing-20);
  }

  .documents-content {
    grid-template-columns: 1fr;
    gap: var(--spacing-48);
  }

  .documents-text {
    max-width: 100%;
  }

  .documents-visual {
    justify-content: center;
    order: -1;
  }

  .documents-title {
    font-size: 48px;
    white-space: normal;
  }

  .pd-interface-image {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .documents-section {
    padding: 60px var(--spacing-16);
  }

  .documents-title {
    font-size: 40px;
  }

  .documents-description {
    font-size: var(--font-md);
  }

  .platform-label {
    padding: 0;
    margin-bottom: var(--spacing-12);
  }
}

@media (max-width: 480px) {
  .documents-section {
    padding: 48px var(--spacing-12);
  }

  .documents-title {
    font-size: 32px;
  }
}