.variation-section {
  padding: 80px 24px;
  background-color: #f9f9f9;
  min-height: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.variation-container {
  max-width: 900px;
  margin: 0 auto;
}

.tracker-label {
  display: inline-block;
  padding: 4px 12px;
  background-color: transparent;
  color: var(--primary-color);
  border-radius: 16px;
  font-size: var(--font-sm);
  margin-bottom: var(--spacing-24);
}

.variation-title {
  font-weight: 600;
  font-size: 32px;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
  color: var(--primary-text);
  margin-top: 0px;
  margin-bottom: var(--spacing-12);
}

.variation-subtitle {
  font-weight: 400;
  font-size: var(--font-lg);
  line-height: 1.5;
  text-align: center;
  color: var(--primary-text);
  max-width: 704px;
  margin: 0 auto;
}

.variation-dash {
  color: var(--secondary-text);
}

@media (max-width: 1024px) {
  .variation-section {
    padding: 70px var(--spacing-20);
  }

  .variation-title {
    font-size: 28px;
  }

  .variation-subtitle {
    font-size: var(--font-md);
  }
}

@media (max-width: 768px) {
  .variation-section {
    padding: 60px var(--spacing-16);
    text-align: left;
  }

  .variation-container {
    max-width: 100%;
  }

  .variation-title {
    font-size: 24px;
    text-align: left;
  }

  .variation-subtitle {
    text-align: left;
  }

  .tracker-label {
    font-size: var(--font-sm);
    padding: 0;
    margin-bottom: var(--spacing-20);
  }
}

@media (max-width: 480px) {
  .variation-section {
    padding: 48px var(--spacing-12);
  }

  .variation-title {
    font-size: 22px;
  }
}
