@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

/* :root {
  --primary-text: #1f1f1f;
  --secondary-text: #555555;
  --active-text: #1e7eb2;
  --background-white: #fff;
  --primary-hover: #0052cc;

  --primary-color: #1782e0;

  --border-color: #edeef1;

  --spacing-4: 4px;
  --spacing-8: 8px;
  --spacing-12: 12px;
  --spacing-16: 16px;
  --spacing-20: 20px;
  --spacing-24: 24px;
  --spacing-32: 2rem;
  --spacing-40: 2.5rem;
  --spacing-80: 5rem;

  --font-xs: 12px;
  --font-sm: 14px;
  --font-md: 16px;
  --font-lg: 18px;
  --font-xl: 20px;
} */
:root {
  /* Text Colors */
  --primary-text: #1f1f1f;
  --secondary-text: #555555;
  --active-text: #1e7eb2;
  --background-white: #fff;

  /* Brand Colors */
  --primary-color: #1782e0;
  --primary-hover: #0052cc;

  /* Border Colors */
  --border-color: #edeef1;

  /* Spacing */
  --spacing-4: 4px;
  --spacing-8: 8px;
  --spacing-12: 12px;
  --spacing-16: 16px;
  --spacing-20: 20px;
  --spacing-24: 24px;
  --spacing-32: 32px;
  --spacing-40: 40px;
  --spacing-48: 48px;
  --spacing-64: 64px;
  --spacing-80: 80px;

  /* Font Sizes */
  --font-xs: 12px;
  --font-sm: 14px;
  --font-md: 16px;
  --font-lg: 18px;
  --font-xl: 20px;
}
.big-blue-bullet {
  font-size: 32px; /* Adjust size */
  color: var(--primary-hover); /* Set color */
}
.flex-center {
  display: flex;
  align-items: center;
}
.text-justify{
  text-align: justify;
}
.ty-10{
  transform: translateY(-10px);
}
@keyframes pulse {
  0% {
    opacity: 0.6;
    transform: scale(1);
  }
  50% {
    opacity: 0.1;
    transform: scale(1.05);
  }
  100% {
    opacity: 0.6;
    transform: scale(1);
  }
}

.primary-border {
  border: 1px solid var(--border-color);
}

.boder-top {
  border-top: 1px solid #eeeeee;
}
.text-center {
  text-align: center;
}

body {
  font-family: "Roboto", sans-serif !important;
}
.mb-0 {
  margin-bottom: 0px;
}
.mb-4 {
  margin-bottom: 4px;
}
.mt-0 {
  margin-top: 0px;
}
.my-0 {
  margin-bottom: 0px;
  margin-top: 0px;
}
.lh-0 {
  line-height: 0px;
}
.bg-transparent {
  background-color: transparent !important;
}
.demo-button {
  max-width: 230px;
}
.demo-button:hover,
.custom-btn:hover {
  color: #fff !important;
  text-decoration: none !important;
}
.loader-overlay {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  overflow: hidden;
}

.loader-container {
  /* background-color: white; */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  border-radius: 4px;
  padding: 16px;
  animation: blink 1.5s ease-in-out infinite;
}

.loader-image {
  display: block;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

@keyframes blink {
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}
.input-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 250px; /* Adjust width as needed */
}

.custom-input {
  width: 100%;
  padding: 8px 12px;
  padding-right: 40px; /* Space for button */
  border: 1px solid var(--border-color);
  border-radius: 6px;
  outline: none;
  transition: border-color 0.2s ease-in-out;
}

.custom-input::-webkit-outer-spin-button,
.custom-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-button {
  position: absolute;
  right: 1px;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--primary-color);
  border: none;
  padding: 4px;
  border-radius: 0px 4px 4px 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.2s ease-in-out;
  width: 32px;
  height: 32px;
  font-size: 18px;
  color: #fff;
}

.custom-button:hover {
  background-color: var(--primary-hover);
}

.icon {
  width: 16px;
  height: 16px;
  stroke: white;
}
