.tracking-section {
  background-color: #fff;
  width: 100%;
  padding: 80px 80px;
  border-top: 1px solid var(--border-color);
}

.tracking-container {
  max-width: 1500px;
  margin: 0 auto;
}

.tracking-header {
  text-align: center;
  margin-bottom: 64px;
}

.tracking-label {
  display: inline-block;
  padding: 4px 12px;
  background-color: #e6edff;
  color: var(--primary-color);
  border-radius: 16px;
  font-size: var(--font-sm);
  margin-bottom: 0px;
}

.tracking-title {
  font-size: 48px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 24px;
  color: var(--primary-text);
  margin-top: 16px;
}

.tracking-description {
  font-size: var(--font-lg);
  line-height: 1.6;
  color: var(--secondary-text);
  max-width: 800px;
  margin: 0 auto;
}

.feature-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
}

.feature-card {
  background: #fff;
  border-radius: 16px;
  border: 1px solid var(--border-color);
}

.feature-card img {
  border-bottom: 1px solid var(--border-color);
  padding: 24px 24px 0px;
}

.feature-card-text {
  padding: 0px 24px 24px;
}

.h260 {
  height: 260px !important;
}

.feature-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 0px;
}

.feature-title {
  font-size: 24px;
  font-weight: 600;
  color: var(--primary-text);
  margin-bottom: 0px;
}

.feature-description {
  font-size: var(--font-md);
  line-height: 1.6;
  color: var(--secondary-text);
  margin-top: 12px;
}

@media (max-width: 1024px) {
  .tracking-section {
    padding: 60px var(--spacing-20);
  }

  .tracking-title {
    font-size: 42px;
  }

  .tracking-description {
    font-size: var(--font-md);
  }

  .feature-cards {
    grid-template-columns: repeat(2, 1fr);
    gap: var(--spacing-20);
  }

  .feature-title {
    font-size: 22px;
  }
}

@media (max-width: 768px) {
  .tracking-section {
    padding: 48px var(--spacing-16);
  }

  .tracking-header {
    text-align: left;
    margin-bottom: var(--spacing-32);
  }

  .tracking-title {
    font-size: 36px;
  }

  .tracking-description {
    margin-left: 0;
    margin-right: 0;
  }

  .feature-cards {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .tracking-section {
    padding: 40px var(--spacing-12);
  }

  .tracking-title {
    font-size: 32px;
  }

  .tracking-label {
    font-size: var(--font-md);
  }

  .feature-card img {
    padding: var(--spacing-16) var(--spacing-16) 0;
  }

  .feature-card-text {
    padding: 0 var(--spacing-16) var(--spacing-16);
  }
  .hide-sm{
    display: none;
  }
}
