.ScheduleDemoContainer {
  height: 100vh;
  margin: 0 auto;
  background-color: var(--background-white);
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.ScheduleDemoContainer iframe {
  border: none;
  background: transparent;
  scrollbar-width: none; /* Hides scrollbar in Firefox */
  -ms-overflow-style: none; /* Hides scrollbar in IE/Edge */
}

.ScheduleDemoContainer iframe::-webkit-scrollbar {
  display: none; /* Hides scrollbar in Chrome/Safari */
}
