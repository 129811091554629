.privacy-container {
  padding: 48px 24px;
  background-color: #fff;
  min-height: 100vh;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.privacy-content {
  max-width: 800px;
  margin: 0 auto;
}

.privacy-container h1 {
  font-size: 32px;
  font-weight: 700;
  color: #000;
  margin-bottom: 24px;
}

.privacy-container h2 {
  font-size: 24px;
  font-weight: 600;
  color: #000;
  margin: 32px 0 16px;
}

.privacy-container p {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  margin-bottom: 16px;
}

.privacy-container section {
  margin-bottom: 32px;
}

.privacy-container ul {
  list-style-type: disc;
  padding-left: 24px;
  margin-bottom: 16px;
}

.privacy-container li {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  margin-bottom: 8px;
}

.privacy-container a {
  color: var(--primary-color);
  text-decoration: none;
  transition: color 0.2s ease;
}

.privacy-container a:hover {
  color: #0052cc;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .privacy-container {
    padding: 32px 16px;
  }

  .privacy-container h1 {
    font-size: 28px;
  }

  .privacy-container h2 {
    font-size: 20px;
  }

  .privacy-container p,
  .privacy-container li {
    font-size: 15px;
  }
}

@media (max-width: 480px) {
  .privacy-container {
    padding: 24px 16px;
  }

  .privacy-container h1 {
    font-size: 24px;
  }

  .privacy-container h2 {
    font-size: 18px;
  }
}
